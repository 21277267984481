import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/Meta"
import Layout from "../components/Layout"
import Container from "../components/common/Container"
import DemoForm from "../components/DemoForm"
import RichText from "../components/RichText"
import styled from "styled-components"
import { sm, md } from "../utils/breakpoints"
import { ContactSales } from "../slices/ContactPageForm/styles"

const FormWrapper = styled(ContactSales)`
  margin-top: 3rem;
  padding: 0;
  margin-bottom: 3rem;

  form {
    margin-top: 0;
  }

  @media ${md} {
    padding: 0 2rem;
    width: 100%;
    margin-top: 8rem;
  }
`

const DemoGrid = styled.div`
  @media ${md} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 12rem;
    margin-top: 8rem;
  }
  max-width: 120rem;
  margin: 0 auto;
  margin-top: 3rem;

  img {
    display: none;
  }

  @media ${md} {
    img {
      display: block;
      max-width: 26rem;
    }
  }
`

const MobileOnly = styled.div`
  img {
    display: block;
    margin: 0 auto;
    max-height: 20rem;
  }
h
  @media ${sm} {
    img {
      max-height: 25rem;
    }
  }

  @media ${md} {
    display: none;
  }
`

const BookDemo = ({ data, pageContext, location }) => {
  const page = data.prismicBookADemo.data
  const defaultSupportingUmageUrl = "https://images.prismic.io/yarno/5c850816-3a10-4484-add1-22a80240432d_Hand_holding_phone.png?auto=compress%2Cformat&fit=max&q=80&w=1000&h=1636&fm=webp"
  const supportingImageUrl = page?.supporting_image.url || defaultSupportingUmageUrl
  const buttonLabel = page?.form_button_label || "Request demo"
  const successMessage = page?.form_success_message || "We'll be in touch!"

  return (
    <Layout
      hideSalesCTA
      location={location}
      prismicLayout={data.prismicLayout}
      prismicSiteBanner={data.prismicSitebanner}>
      <Meta
        title={page.meta_title}
        description={page.meta_description}
        canonical={location.href}
      />
      <Container>
        <DemoGrid>
          <div>
            <RichText render={page?.main_content.richText} />
            <img src={supportingImageUrl} alt={page?.supporting_image_alt_text} />
          </div>
          <div>
            <FormWrapper>
              <DemoForm
                successMessage={successMessage}
                submitButtonLabel={buttonLabel}
              />
            </FormWrapper>
          </div>
        </DemoGrid>

        <MobileOnly>
          <img src={supportingImageUrl} alt={page?.supporting_image_alt_text} />
        </MobileOnly>
      </Container>
    </Layout>
  )
}

export default BookDemo

export const postQuery = graphql`
  query {
    ...PrismicSiteBanner
    ...PrismicLayout
    prismicBookADemo {
      data {
        main_content {
          richText
        }
        form_button_label
        form_success_message
        supporting_image {
          url
        }
        supporting_image_alt_text
        meta_title
        meta_description
        body {
          ... on PrismicBookADemoDataBodyGeneralCard {
            id
            slice_label
            slice_type
            primary {
              description {
                richText
              }
              image {
                url
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
  }
`